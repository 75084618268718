<template>
  <svg
    width="133"
    height="64"
    viewBox="0 0 133 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M119.373 0H123.878V1.3824H122.522V5.9648H120.73L120.755 1.3824H119.373V0ZM124.826 0H127.181L128.614 3.84L130.176 0H132.378V5.9904H130.739L130.714 2.2272L129.178 5.9904H127.974L126.438 2.0992V5.9904H124.851V0H124.826Z"
      fill="#B9A096"
    />
    <path
      d="M86.8352 16.896V0H74.88V11.52C79.0784 12.9024 83.072 14.72 86.8352 16.896Z"
      fill="#B9A096"
    />
    <path
      d="M80.8704 43.8784C80.4352 43.4688 79.9744 43.0848 79.5392 42.7008C79.488 42.6752 79.4624 42.624 79.4112 42.5984C78.976 42.24 78.5408 41.8816 78.08 41.5232C78.0032 41.472 77.952 41.4208 77.8752 41.3696C77.44 41.0368 76.9792 40.704 76.5184 40.3968C76.4416 40.3456 76.3904 40.2944 76.3136 40.2688C75.8528 39.9616 75.3664 39.6544 74.88 39.3472L74.8544 39.3216H35.8912V0H23.936V63.232H35.8912V51.2768H74.88V63.232H86.8352V51.0464C85.1456 48.4096 83.1232 46.0032 80.8704 43.8784Z"
      fill="#B9A096"
    />
    <path
      d="M98.7904 0V35.7888C88.4992 23.1936 72.8576 15.1552 55.3728 15.1552C50.7136 15.1552 46.1824 15.7184 41.856 16.7936V29.2352C46.1056 27.8528 50.6624 27.1104 55.3728 27.1104C76.9792 27.1104 94.976 42.7008 98.7648 63.232H110.771V0H98.7904Z"
      fill="#B9A096"
    />
    <path
      d="M11.9552 35.7888V0H0V63.232H12.0064C13.0304 57.7024 15.0784 52.5312 17.9456 47.9232V29.5168C15.7952 31.4624 13.7984 33.5616 11.9552 35.7888Z"
      fill="#B9A096"
    />
  </svg>
</template>

<style lang="scss" scoped>
svg {
  width: 100%;
  height: 100%;
}
</style>
